import React from 'react';
import styled from 'styled-components/macro';
import HomeOne from '../images/home-1.jpg';
import HomeTwo from '../images/home-2.jpg';
import HomeThree from '../images/home-3.jpg';
import HomeFour from '../images/home-4.jpg';
import HomeFive from '../images/home-5.jpg';
import HomeSix from '../images/home-6.jpg';

const Section = styled.section`
  width: 100%;
  height: 100%;
  padding: 1rem calc((100vw - 1300px) / 2);
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 1rem 0rem 0 0;
  margin-bottom: 3rem;
`;

const Heading = styled.div`
  font-size: 1.8rem;
  padding: 2rem 1rem;
  margin-bottom: 40px;

  @media screen and (max-width: 768px) {
    text-align: start;
  }
`;

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem 0rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const InfoWrap = styled.div`
  padding: 0rem 1rem;
  min-height: 400px;
  height: 100%;

  h2 {
    padding-top: 0.5rem;
    margin-bottom: 1.5rem;
    font-weight: 550;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 3rem;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  max-width: 600px;
  max-height: 400px;
  object-fit: cover;
  margin-bottom: 0rem;
`;

const Listings = () => {
  return (
    <Section>
      <Container name='courspictures'>
        <Heading>
          <h1
            data-aos='fade-right'
            data-aos-duration='1000'
            data-aos-once='false'
            data-aos-anchor-placement='center bottom'
          >
            Rejoignez ceux qui ont sauté le pas !
          </h1>
        </Heading>
        <InfoRow>
          <InfoWrap
            data-aos='zoom-out-up'
            data-aos-duration='1200'
            data-aos-once='false'
            data-aos-anchor-placement='center bottom'
          >
            <Image src={HomeOne} alt='home' />
            <h2>Séance en small groupe sur Loriol-du-Comtat</h2>
          </InfoWrap>
          <InfoWrap
            data-aos='zoom-out-down'
            data-aos-duration='1200'
            data-aos-once='false'
            data-aos-anchor-placement='center bottom'
          >
            <Image
              src={HomeTwo}
              alt='home'
              css={`
                margin-top: 120px;
                @media screen and (max-width: 768px) {
                  margin-top: 0px;
                }
              `}
            />
            <h2>Coaching personalisé à domicile à Villes-sur-Auzon</h2>
          </InfoWrap>
        </InfoRow>
        <InfoRow>
          <InfoWrap
            data-aos='zoom-out-up'
            data-aos-duration='1200'
            data-aos-once='false'
            data-aos-anchor-placement='center bottom'
          >
            <Image src={HomeThree} alt='home' />
            <h2>Apprentissage natation à la Bull'Aquatic de <br></br>l'Isle sur sorgue </h2>
          </InfoWrap>
          <InfoWrap
            data-aos='zoom-out-down'
            data-aos-duration='1200'
            data-aos-once='false'
            data-aos-anchor-placement='center bottom'
          >
            <Image
              src={HomeSix}
              alt='home'
              css={`
                margin-top: 120px;
                @media screen and (max-width: 768px) {
                  margin-top: 0px;
                }
              `}
            />
            <h2>Cours de cross training à ADN Gym sur Carpentras</h2>
          </InfoWrap>
        </InfoRow>
        <InfoRow>
          <InfoWrap
            data-aos='zoom-out-up'
            data-aos-duration='1200'
            data-aos-once='false'
            data-aos-anchor-placement='center bottom'
          >
            <Image src={HomeFive} alt='home' />
            <h2>Cours collectif à la Bull'Aquatic de l'Isle sur <br></br>la sorgue</h2>
          </InfoWrap>
          <InfoWrap
            data-aos='zoom-out-down'
            data-aos-duration='1200'
            data-aos-once='false'
            data-aos-anchor-placement='center bottom'
          >
            <Image
              src={HomeFour}
              alt='home'
              css={`
                margin-top: 120px;
                @media screen and (max-width: 768px) {
                  margin-top: 0px;
                }
              `}
            />
            <h2>Séance en small groupe sur Loriol-du-Comtat</h2>
          </InfoWrap>
        </InfoRow>
      </Container>
    </Section>
  );
};

export default Listings;
