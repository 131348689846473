import React, { useRef } from 'react';
import styled from 'styled-components';
import emailjs from 'emailjs-com';
import './contact.css';

const Section = styled.section`
  background: #fff;
  padding: 1rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Container = styled.div`
  background: #fff;
  padding: 3rem 1rem;
  position: relative;
`;

const Wrap = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;


const Content = styled.div`
  flex: 0 0 50%;

  @media screen and (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 0px;
  }



  p {
    margin-bottom: 1rem;
    line-height: 1.5;
  }
`;



const Contact = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('Gmail', 'template_iw3ztkp', form.current, 'kpxEvPGPbkfaSjI2D')
      .then((result) => {
          console.log(result.text);
          console.log('email envoyé')
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  };

  return (
    <Section>
      <Container name='contact'>
        <Wrap>
            <Content
              data-aos='fade-left'
              data-aos-duration='1200'
              data-aos-delay='300'
              data-aos-once='false'
              data-aos-anchor-placement='center bottom'
            >
                  {/* <form >
                    <label></label>
                      <input type="text" name="name" placeholder='Name' />
                    <label></label>
                      <input type="email" name="email" placehorlder="Email"/>
                    <label></label>
                      <input type="text" name="subject" />
                    <label></label>
                      <textarea name="message" />
                      <input type="submit" value="Send" />
                </form> */}

            <div class="card card-3">
                <div class="card-body">
                    <h2 class="title">Contact</h2>
                    <h3 class='title2'> Vous pouvez me contacter par <strong>téléphone</strong> au 06 13 48 95 58,<br></br> par <strong>mail</strong> à l'adresse suivante <span class='spanben'>benjaminbrusset@yahoo.fr</span> <br></br> ou via ce <strong>formulaire de contact</strong></h3>
                    
                    <form ref={form} onSubmit={sendEmail}>
                        <div class="input-group">
                            <input class="input--style-3" type="text" name="nom" placeholder="Nom et prénom" />
                        </div>
                        <div class="input-group">
                            <input class="input--style-3" type="email" name="email" placeholder="Email"/>
                        </div>

                        <div class="input-group">
                            <input class="input--style-3" type="text" name="subject" placeholder='Sujet'/>
                        </div>
                        <div class="input-group">
                            <input class="input--style-4" type="text" name="message" placeholder="Message" />
                        </div>
                        <div class="p-t-10">
                            <button class="btn btn--pill btn--green jello-horizontal" type="submit" value="Send">Envoyer</button>
                        </div>
                    </form>
                </div>
            </div>

            </Content>
        </Wrap>
      </Container>
    </Section>
  );
};

export default Contact;
