import ImageOne from '../images/infosport3.jpg';


export const InfoDataThree = {
  heading: 'Pourquoi ?',
  paragraphOne:
    "Vous souhaitez lutter contre le vieillissement, améliorer votre bien-être, viser un amincissement, un renforcement musculaire, augmenter le volume musculaire, augmenter votre force ?",
  paragraphTwo:
    "Ou peut-être améliorer votre système cardio-respiratoire, maintenir votre physique durant votre grossesse, effectuer une réathlétisation, vous préparez à une compétition, suivre une initiation ou un perfectionnement à l'halthérophilie ou encore apprendre et perfectionner les quatres nages de natation ?",
  paragraphThree:
    "Si vous vous sentez concerné par ces différents objectifs alors regardons ensemble comment je peux vous les faire atteindre.",
  buttonLabel: 'Comment ?',
  image: ImageOne,
  reverse: false,
  delay: 100,
  name: "cours3"
};

