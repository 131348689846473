import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Button = styled(Link)`
  background: ${({ primary }) => (primary ? '#000d1a' : '#000d1a')};
  white-space: nowrap;
  outline: none;
  border: none;
  min-width: 100px;
  max-width: 200px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ big }) => (big ? '16px 40px' : '14px 24px')};
  color: ${({ primary }) => (primary ? '#fff' : '#fff')};
  font-size: ${({ big }) => (big ? '20px' : '14px')};
  border-radius: ${({ round }) => (round ? '50px' : '0px')};
  animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  @keyframes text-focus-in {
    0% {
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      filter: blur(0px);
      opacity: 1;
    }
  }

  &:hover {
    transform: translateY(-2px);
  }
  
`;
