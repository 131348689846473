import ImageOne from '../images/tarif.png';

export const InfoDataFour = {
  heading: 'Mes Tarifs',
  buttonLabel: 'Me contacter',
  image: ImageOne,
  reverse: false,
  delay: 100,
  name: "tarif"
};

