import React from 'react';
import styled from 'styled-components';
import { Button } from './Button';
import {Link} from 'react-scroll';
const Section = styled.section`
  width: 100%;
  height: 100%;
  padding: 2rem 0rem;
`;

const Container = styled.div`
  padding: 2rem calc((100vw - 1300px) / 2);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 800px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  line-height: 1.4;
  padding: 1rem 1rem;
  order: ${({ reverse }) => (reverse ? '2' : '1')};
  
  @media screen and (max-width: 768px) {
    padding: 0rem 1rem;
    margin: 0;
  }
  h1 {
    margin-bottom: 1rem;
    font-size: clamp(1.5rem, 6vw, 2rem);
  }

  p {
    margin-bottom: 2rem;
  }
`;

const ColumnRight = styled.div`
  padding: 1rem 2rem;
  order: ${({ reverse }) => (reverse ? '1' : '2')};
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    order: ${({ reverse }) => (reverse ? '2' : '1')};
    padding: 0;
    margin: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media screen and (max-width: 768px) {
      width: 95%;
      height: 95%;
      object-fit: fill;
    }
  }
`;

const InfoSection = ({
  heading,
  paragraphOne,
  paragraphTwo,
  paragraphThree,
  buttonLabel,
  reverse,
  image,
  delay,
  name,
  goto
}) => {
  return (
    <Section>
      <Container name={name}>
        <ColumnLeft
          reverse={reverse}
          data-aos='fade-up'
          data-aos-duration='1200'
          data-aos-once='false'
          data-aos-delay={delay}
          data-aos-anchor-placement='center bottom'
        >
          <h1>{heading}</h1>
          <p>{paragraphOne}</p>
          <p>{paragraphTwo}</p>
          <p>{paragraphThree}</p>

          <Link to={goto} spy={true} smooth={true} offset={-50} duration={500}>
            <Button primary='true'>
            {buttonLabel}
            </Button>
          </Link>
            
       
        </ColumnLeft>
        <ColumnRight reverse={reverse}>
          <img
            src={image}
            alt='home'
            data-aos='zoom-out'
            data-aos-duration='1200'
            data-aos-once='false'
            data-aos-delay={delay}
            data-aos-anchor-placement='center bottom'
          />
        </ColumnRight>
      </Container>
    </Section>
  );
};

export default InfoSection;
