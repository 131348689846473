import React from 'react';
import styled from 'styled-components';
import { TransformWrapper, TransformComponent} from "react-zoom-pan-pinch";

const Section = styled.section`
  width: 100%;
  height: 100%;
  padding: 4rem 0rem;
`;

const Container = styled.div`
  padding: 2rem calc((100vw - 1300px) / 2);
  display: grid;
  grid-template-columns: 1fr;

  @media screen and (max-width: 768px) {
    padding 0rem 1rem;
  }
`;

const ColumnLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  line-height: 1.4;
  padding: 1rem 1rem;
  order: ${({ reverse }) => (reverse ? '2' : '1')};

  h1 {
    margin-bottom: 0.5rem;
    font-size: clamp(1.5rem, 6vw, 2rem);
    text-align: center;
  }

  p {
    margin-bottom: 2rem;
  }
  @media screen and (max-width: 768px) {
    padding: 0;
  }
  }
`;

const ColumnRight = styled.div`
order: ${({ reverse }) => (reverse ? '2' : '1')};
padding: 0;
margin: 0;

  @media screen and (max-width: 768px) {

  }

  img {
    width: 100%;
    height: 100%;
    object-fit: fill;

    @media screen and (max-width: 768px) {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
`;

const InfoSection3 = ({
  heading,
  reverse,
  image,
  delay,
  name,

}) => {
  return (
    <Section>
      <Container name={name}> 
        <ColumnLeft
          reverse={reverse}
          data-aos='fade-up'
          data-aos-duration='1200'
          data-aos-once='false'
          data-aos-delay={delay}
          data-aos-anchor-placement='center bottom'
        >
          <h1>{heading}</h1>
        </ColumnLeft>
        <ColumnRight reverse={reverse}>
          <TransformWrapper defaultScale={1} defaultPositionX={100} defaultPositionY={200}>
            <TransformComponent>
            <img
            src={image}
            alt='home'
            data-aos='flip-down'
            data-aos-duration='1200'
            data-aos-once='false'
            data-aos-delay={delay}
            data-aos-anchor-placement='center bottom'
          />
            </TransformComponent>
          </TransformWrapper>
          
        </ColumnRight>
      </Container>
    </Section>
  );
};

export default InfoSection3;
