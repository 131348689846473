import React from 'react';
import styled from 'styled-components';
import { Button } from './Button';
import ImageOne from '../images/work1.jpg';
import {Link} from 'react-scroll';

const Section = styled.section`
  background: #000d1a;
  padding: 13rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 768px) {
    padding: 8rem 0rem;
  }
`;

const Container = styled.div`
  background: #fff;
  padding: 6rem 2rem;
  position: relative;
`;

const Wrap = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const ColumnLeft = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0px -15px;
  justify-content: flex-start;
  padding: 1rem;
`;

const Content = styled.div`
  flex: 0 0 50%;

  @media screen and (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 250px;
  }

  h1 {
    margin-bottom: 2rem;
    font-size: 2rem;
  }

  p {
    margin-bottom: 1rem;
    line-height: 1.5;
  }
`;

const ColumnRight = styled.div`
  position: absolute;
  top: -80px;
  right: 0;
  max-width: 850px;
  height: 140%;
  width: 45%;
  padding-left: 1rem;

  @media screen and (max-width: 768px) {
    height: 320px;
    top: -65px;
    width: 80%;
    margin: 0 auto;
    left: 0;
  }
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const Features = () => {
  return (
    <Section name='features'>
      <Container >
        <Wrap>
          <ColumnLeft>
            <Content
              data-aos='fade-right'
              data-aos-duration='1200'
              data-aos-delay='400'
              data-aos-once='false'
              data-aos-anchor-placement='center bottom'
            >
              <h1>Comment je travaille ?</h1>
              <p>
                Le premier rendez-vous est réservé à un questionnaire pour déterminer vos objectifs et être au plus proche de vos attentes. Chacunes des séances est personnalisée afin d'atteindre vos objectifs en prenant compte de vos particularités.
              </p>
              <p>
                Le suivi est réalisé par divers tests du début de la prise en charge tout au long du coaching ce qui vous permettra de visualiser votre progression.
                Des conseils nutritionnels seront transmis également.
              </p>
              <p>
                Pour les programmations : la première séance est en présentiel ainsi qu'une séance par mois pour faire un bilan et le point ensemble.
              </p>

            <Link to="courspictures" spy={true} smooth={true} offset={-30} duration={500}>
              <Button>Mes cours</Button>
            </Link>

            </Content>
          </ColumnLeft>
          <ColumnRight>
            <Image
              src={ImageOne}
              data-aos='fade-left'
              data-aos-duration='1200'
              data-aos-once='false'
              data-aos-anchor-placement='center bottom'
            />
          </ColumnRight>
        </Wrap>
      </Container>
    </Section>
  );
};

export default Features;
